import React from 'react'
import {
  writeFile,
  readFile
} from 'utils/fs-helpers'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

import SectionBuilder from '@/SectionBuilder'

import {
  getPageData,
  getHeaderFooterData,
  getRedirects,
  getAllResultsFromEntity
} from 'services/API'

import {
  generateSlug,
  normalizeData,
  normalizeMetadata,
  normalizeAdditionalData,
} from 'services/API/helpers'

/**
 * You've just opened the project, you're here and you're confused.
 * It's normal, don't panic.
 *
 * All the pages for this website are built dynamically with the API data (the Strapi CMS)
 * All paths are build in the getStaticPaths function
 * Then, when the page is called (in our case, at build time) is executed the getStaticProps function
 *
 * Problem: Next doesn't pass other params that the one used as name of this file (in this case: slug)
 * Solution: create a cached filed in getStaticPaths with all useful data which is read in getStaticProps
 * https://github.com/vercel/next.js/discussions/11272#discussioncomment-102845
 */


export const getStaticPaths = async () => {
  const entitiesToGet = [
    {
      entity: ['pages', 'scenarios', 'services', 'news'], params: {
        locale: 'all',
        'pagination[pageSize]': 100
      }
    }
  ]

  const entities = entitiesToGet.reduce((acc, curr) => {
    if (!Array.isArray(curr.entity)) return [...acc, curr]

    curr.entity.forEach(collection => {
      acc.push({
        entity: collection,
        params: curr.params
      })
    })

    return acc
  }, [])


  const pages = await Promise.all(entities.map(({
    entity,
    params
  }) => getAllResultsFromEntity({
    entity, params
  })))

  const redirects = await getRedirects()
  const pagesPaths = pages.reduce((acc, data) => {
    if (!data) return acc

    const pathData = Array.isArray(data) ? data : [data]
    const paths = pathData.map(({
      id,
      attributes
    }) => {
      return {
        params: {
          id,
          slug: generateSlug(attributes, redirects),
        },
        locale: attributes.locale
      }
    })

    return  [...acc, ...paths]
  }, [])

  try {
    const pagesCache = pages.reduce((acc, data) => {
      if (!data || data.length === 0) return acc

      const pathData = Array.isArray(data) ? data : [data]
      const paths = pathData.map(({
        id,
        attributes,
        entity,
      }) => {
        return {
          id,
          apiSlug: attributes.slug,
          slug: generateSlug(attributes, redirects),
          locale: attributes.locale,
          isHomepage: attributes.homepage,
          entity,
        }
      })


      return  [...acc, ...paths]
    }, [])

    await writeFile('static-paths', JSON.stringify([...pagesCache]), 'utf-8')
  } catch (error) {
    console.error('Generate cache file error', error)
  }

  return {
    paths: [...pagesPaths],
    fallback: false
  }
}

export const getStaticProps = async ({
  params,
  locale: currentLocale
}) => {
  const cachefile = await readFile('static-paths', 'utf-8')
  const staticPaths = JSON.parse(cachefile)

  const {
    entity,
    locale,
    apiSlug
  } = staticPaths.find((path) => {
    if (params.slug) {
      const slug = Array.isArray(path.slug) ? path.slug.join('/') :  path.slug
      return  slug === params.slug.join('/') && path.locale === currentLocale
    }

    return path.isHomepage && path.locale === currentLocale
  })


  const [page, headerFooterData, redirects] = await Promise.all([
    getPageData({
      entity,
      slug: apiSlug,
      locale,
    }),
    getHeaderFooterData({ locale }),
    getRedirects()
  ])

  const data = Array.isArray(page.data) ? page.data[0].attributes : page.data.attributes || {}
  const metadata = await normalizeMetadata(data, page.options)
  const additionalData = await normalizeAdditionalData(data, redirects)

  if (data.intro) {
    data.intro.asTitle = true
    data.sections = [data.intro, ...data.sections]
  }

  const sections =  await normalizeData(data.sections, locale, additionalData)
  data.sections = sections

  return {
    props: {
      data,
      additionalData,
      menus: {
        header: headerFooterData.headerMenu || null,
        footer: headerFooterData.footerData || null,
      },
      options: page.options,
      metadata,
    }
  }
}

export default function Page({ data, }) {
  return (
    <SectionBuilder sections={data.sections} />
  )
}
